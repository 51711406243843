import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { profileStructure as defaultStructure } from "@energylab/shared-components/components/profile/profileForm/config";
import { companies } from "./companies";

const profileStructure = !defaultStructure ? {} : {
    ...defaultStructure,
    formElements: [
        ...defaultStructure.formElements,
        {
            title: "auth.fields.company",
            type: "select",
            options: companies.map(({ label, value }) => ({
                title: <FormatMessage id={label} />,
                value
            })),
            property: "company",
            required: true
        }
    ]
};

export { profileStructure };
