import environment from "environment";

import { FC, lazy } from "react";

const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));
const ArticlesContainer = lazy(() => import("@energylab/shared-components/modules/news/articles/articlesContainer"));
const SsoComponent: FC = () => {
    window.location.replace(`${environment.auth.aginsurance}?jwt=${environment.anonymousToken}`);
    return null;
}

const AG_INSURANCE_ROUTE = {
    path: "aginsurance",
    element: <SsoComponent />
}

export const ROUTES = [
    {
        path: "go-forest",
        element: <ContentContainer type="goForest" />
    },
    {
        path: "evi",
        element: <ArticlesContainer type="evi" places={["articles_header_evi"]} useThemes={false} />
    },
    AG_INSURANCE_ROUTE
];

export const PUBLIC_ROUTES = [
    AG_INSURANCE_ROUTE
]
