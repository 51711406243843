const comparebyValue = (a: {value: string}, b: {value: string}) => {
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();

    if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    
      return 0;
}

const companies = [
    { label: "AG Insurance", value: "AGInsurance" },
    { label: "AG Real Estate", value: "AGRealEstate" },
    { label: "Ageas UK", value: "AgeasUK" },
    { label: "Ageas Portugal", value: "AgeasPortugal" },
    { label: "Ageas France", value: "AgeasFrance" },
    { label: "Aksigorta", value: "Aksigorta" },
    { label: "Agesa", value: "Agesa" },
    { label: "Ageas Asia Regional Office", value: "AgeasAsiaregionaloffice" },
    { label: "Corporate Centre", value: "CorporateCentre" },
    { label: "Ageas Federal Life Insurance", value: "AgeasFederalLifeInsurance" },
    { label: "AG Health Partner", value: "AGHealthPartner" }

]

companies.sort(comparebyValue);
export {companies};
