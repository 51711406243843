import { Theme } from "@energylab/shared-components/utils/styledComponents";
import { generateNewTheme } from "@energylab/shared-components/style/theme";

// TODO: Change primary color
const primaryColor = "#8E419C";

export const theme = generateNewTheme({
    colors: {
        primary: primaryColor,
        primaryHover: primaryColor,
        secondary: "#10C8FF",
        black: "#373737",
        white: "#FFFFFF",
        darkGrey: "#D8DBDC",
        mediumGrey: "#939393",
        subGrey: "#BDBDBD",
        lightGrey: "#F5F5F5",
        statisticsBannerColor: "#F1EDEB",
        statisticsBannerTextColor: "#414141",
        error: "#ED4C4C",
        warning: "#F08B00",
        success: "#AEC92A",
        background: "#F7F9FA"
    },
    activityColors: {
        running: "#8E419C",
        cycling: "#6E6E6E",
        other: "#4565A0",
        swimming: "#7caad6",
        walking: "#96b439"
    },
    breakpoints: {
        header: "1024px",
        footer: "800px",
        innerContainer: "767px",
        profileHeader: "980px",
        profileInfo: "1024px"
    },
    fonts: {
        primary: "Arial, 'Poppins', sans-serif",
        secondary: "Arial, \"Poppins\", sans-serif"
    },
    header: {
        defaultHeight: 80,
        mobileHeight: 80
    },
    footer: {
        defaultHeight: 60,
        mobileHeight: 160
    },
    widgets: {
        margin: 40,
        backgroundOpacity: 0.5
    },
    innerContainer: {
        maxWidth: 1600,
        padding: 80
    },
    shadows: {
        bigShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        smallShadow: "0 2px 4px rgba(0,0,0,0.06), 0 2px 4px rgba(0,0,0,0.10)",
        lineShadow: "0 0 0 1px rgba(0,0,0,0.08)",
        inputShadow: "none",
        insetShadow: "inset 0 1px 3px rgba(0, 0, 0, .25)",
        cardShadow: "0 10px 15px -12px rgba(0,0,0,.1)",
        cardHoverShadow: "0 10px 15px 0 rgba(0, 0, 0, .1)",
        tagShadow: "none"
    },
    borders: {
        inputBorder: "1px solid #C2C2C2"
    },
    borderRadius: {
        bigBorderRadius: "8px",
        smallBorderRadius: "4px",
        tagBorderRadius: "15px"
    }
});

// ANTD Less Vars Replacement
export const ANTD_THEME = {
    primaryColor: "#8E419C",
//     'error-color': "#CF4948",
//     'layout-sider-background': "#282828",
//     'layout-header-background': "#FFFFFF",
//     'layout-header-height': "55px",
//     'layout-header-padding':"0 39px",
//     'menu-dark-color': "#BABABA",
//     'menu-dark-bg': '#282828',
//     'menu-dark-arrow-color': '#FFFFFF',
//     'menu-dark-submenu-bg': '#101010',
//     'menu-dark-highlight-color': '#FDFEFC',
//     'menu-dark-item-active-bg': primaryColor
// }
}
