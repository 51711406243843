// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

body,
html,
#app-container {
    height: 100%;
}

.register-breadText {
    text-align: left !important;
    font-size: 20px !important;
    margin-top: 30px;
}

.ssoButton {
    background-color: #99CC00;
}`, "",{"version":3,"sources":["webpack://./src/style/global.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;;;IAGI,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\nbody,\nhtml,\n#app-container {\n    height: 100%;\n}\n\n.register-breadText {\n    text-align: left !important;\n    font-size: 20px !important;\n    margin-top: 30px;\n}\n\n.ssoButton {\n    background-color: #99CC00;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
