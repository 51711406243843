import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { registerStructure } from "@energylab/shared-components/modules/register/registerForm/config";
import { companies } from "./companies";

const slicedSteps = registerStructure.steps.slice(2);
const registerSteps = [
    registerStructure.steps[0],
    {
        ...registerStructure.steps[1],
        formElements: [
            ...registerStructure.steps[1].formElements,
            {
                title: "form.company",
                type: "select",
                options: companies.map(({ label, value }) => ({
                    title: <FormatMessage id={label} />,
                    value
                })),
                property: "company",
                required: true
            }
        ]
    }
].concat(slicedSteps);

export { registerSteps };
