import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    navbarLogo: "https://res.cloudinary.com/energylab/image/upload/v1637660337/ageas/logo.svg",
    navbarLogoNotLoggedIn: "https://res.cloudinary.com/energylab/image/upload/v1637660337/ageas/logo.svg",
    fullWidth: false,
    navbarItems: [
        {
            id: "about",
            translationKey: "menuItem.about",
            redirectTo: { path: "about" }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "ambassadors",
            translationKey: "menuItem.ambassadors",
            redirectTo: { path: "ambassadors" }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "go-forest",
            translationKey: "menuItem.articles.goforest",
            redirectTo: { path: "go-forest" }
        },
        {
            id: "evi",
            translationKey: "menuItem.evi",
            redirectTo: { path: "evi" }
        },
        {
            id: "gallery",
            translationKey: "menuItem.gallery",
            redirectTo: { path: "gallery" }
        }
    ]
};
