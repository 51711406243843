/* eslint-disable global-require */
import * as environment from "environment";
import { registerStructure } from "@energylab/shared-components/modules/register/registerForm/config";
import { contactConfig } from "@energylab/shared-components/constants/config/contactConfig";
import { profileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { resetPasswordConfig } from "@energylab/shared-components/constants/config/resetPasswordConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { galleryConfig } from "./constants/config/galleryConfig";
import { navbarConfig } from "./constants/config/navbarConfig";
import { registerSteps } from "./constants/registerSteps";
import { profileStructure } from "./constants/profileStructure";
import { footerConfig } from "./constants/config/footerConfig";
import { manualActivityConfig } from "./constants/config/manualActivityConfig";
import { getRoutes, getPublicRoutes } from "@energylab/shared-components/routes";
import { ROUTES, PUBLIC_ROUTES } from "./routes";

const locales = ["pt", "en", "nl", "fr"];
const defaultLocale = "en";
profileConfig.requiredProfileFields = [
    "givenName",
    "familyName",
    "birthDate",
    "sex",
    "company",
    "country",
    "nationality"
];
// TODO: Change company logo
export const companyLogo = "https://res.cloudinary.com/energylab/image/upload/v1637747976/Ageas/ageas.svg";

export const config: ConfigReducerState = {
    navbarConfig,
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    profileConfig: {
        ...profileConfig,
        structure: profileStructure,
        redirectToMainOnSave: true
    },
    registerConfig: {
        backgroundImage: "https://res.cloudinary.com/energylab/image/upload/v1636396593/ageas/luah-jun-yang-lLcAPoKUFMQ-unsplash.jpg",
        requireEmailValidation: true,
        structure: {
            ...registerStructure,
            steps: registerSteps
        }
    },
    resetPasswordConfig,
    galleryConfig,
    ambassadorSettingsConfig: {
        enableRelive: false,
        enableComments: true,
        showTagColorOnAmbassadorCard: true
    },
    socialConfig: {
        disableInstagram: false,
        disableTwitter: false,
        disableFacebook: false,
        disableLinkedIn: false,
        disableOnChallenCard: false,
        hideFacebookOnCommunity: false,
        hideTwitterOnCommunity: false,
        hideInstagramOnCommunity: false,
        hideLinkedInOnCommunity: true
    },
    dashboardPlacesConfig: {
        articles: ["articles_header"],
        benefits: ["benefits_header"],
        challenges: ["challenges_header"],
        events: ["events_header"],
        gallery: ["gallery_header"],
        homePage: ["dashboard"],
        leaderBoard: ["leaderboard_header"],
        mySpace: ["myspace_header"],
        mySpaceActivities: ["myactivities_header"],
        profile: ["profile_header"],
        webShop: ["webshop_header"],
        contact: ["contact_header"]
    },
    ambassadorImage: "",
    auth: {
        facebookRedirect: environment.auth.facebook,
        googleRedirect: environment.auth.google,
        anonymousToken: environment.anonymousToken,
        ssoRedirect: environment.auth.aginsurance
    },
    commentsConfig: {
        disabled: false,
        autoShowSubComments: false,
        autoShowInput: false
    },
    eventsConfig: {
        defaultView: "list",
        viewOptions: []
    },
    contentConfig: {
        hideDateOnCard: false,
        showAuthor: true,
        useThemes: true,
        previewType: "card",
        enablePageBanner: true,
        pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1561558218/generic-v2/bannerimage.jpg",
        pageBannerOverlay: true,
        alignment: "left"
    },
    contactConfig: {
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: contactConfig.siteKey
    },
    companyLogo,
    companyName: "Ageas",
    footerConfig,
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig: {
        enabled: false,
        coinName: "Carbon",
        hideCoinsInActivityTable: true
    },
    likesConfig: {
        disabled: false
    },
    webshopConfig: {
        enabled: false,
        shopName: "",
        directPayment: true,
        hideWalletTransactions: true,
        stockVisualization: "progressLine"
    },
    notificationsConfig: {
        enabled: false
    },
    calculatorConfig: {
        forceMinWindowHeight: true
    },
    providers: [
        "strava",
        "garmin",
        "fitbit",
        "hardlopenmetevy",
        "runkeeper",
        "start2run",
        "pacer",
        "connect",
    ],
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    referralConfig: {
        enabled: true
    },
    routeConfig: {
        locales,
        defaultLocale,
        routes: {
            private: getRoutes(ROUTES),
            public: getPublicRoutes(PUBLIC_ROUTES)
        },
        redirects: [],
        hideNavigationWhenLoggedOut: true
    },
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    tourConfig: {
        showAllTabs: true,
        teamVisualizationAsChallengeProgress: true,
        hideBarChartOnTeamRanking: true
    },
    seo: {
        pageTitle: "Challenge Ageas",
        title: {
            nl: "Challenge Ageas",
            fr: "Challenge Ageas",
            en: "Challenge Ageas"
        },
        description: {
            nl: "",
            fr: "",
            en: ""
        },
        image: "",
        site: ""
    },
    uploadPreset: "ageas1",
    newUsersConfig: {
        enabled: false,
        hasListOfNewUsers: false
    },
    leaderboardConfig: {
        pageSize: 10
    },
    socialShareConfig: {
        enabled: true,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    manualActivityConfig,
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    }
};
